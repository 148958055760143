.fc-event {
  font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}
.fc-col-header-cell-cushion {
  font-family: Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-size: 23px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.fc-event-main {
  cursor: pointer;
}

/* .fc-toolbar.fc-header-toolbar {
  position: sticky;
  top: 102px;
  z-index: 10;
  background: white
}

.fc-head-container.fc-widget-header {
  position: sticky;
  top: 110px;
  z-index: 10;
  background: white;
} */

.fc-dayGridMonth-button.fc-button.fc-button-primary.fc-button-active {
  background: #6191fe;
  box-shadow: none;
}
.fc-timeGridWeek-button.fc-button.fc-button-primary.fc-button-active {
  background: #6191fe;
  box-shadow: none;
}
.fc-timeGridDay-button.fc-button.fc-button-primary.fc-button-active {
  background: #6191fe;
  box-shadow: none;
}
.fc-listMonth-button.fc-button.fc-button-primary.fc-button-active {
  background: #6191fe;
  box-shadow: none;
}
.fc-dayGridMonth-button.fc-button.fc-button-primary {
  background: #9fbcff;
}
.fc-timeGridWeek-button.fc-button.fc-button-primary {
  background: #9fbcff;
}
.fc-timeGridDay-button.fc-button.fc-button-primary {
  background: #9fbcff;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: var(--fc-today-bg-color);
}
.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: none;
}
:root {
  --fc-button-border-color: #9fbcff;
  --fc-button-hover-border-color: #9fbcff;
  --fc-button-active-bg-color: #9fbcff;
  --fc-button-bg-color: #9fbcff;
  --fc-button-hover-bg-color: #9fbcff;
  --fc-today-bg-color: #f4fcff;
}
